import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation to detect route change
import './desktopNavbar.css';
import SearchBox from '../SearchBox/searchBox';
import recyclingLogo from '../../media/images/recycling-logo.png';
import greenLoopsLogo from '../../media/images/green-loops-logo.png'; // Import the official logo
import { useCart } from '../../contexts/cartContext'; // Import useCart hook

function DesktopNavbar() {
  const { getTotalQuantity } = useCart(); // Get cartItemCount from context
  const location = useLocation(); // Hook to get the current location

  // Scroll to top when the location changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, [location]);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-title-link">
          <img src={greenLoopsLogo} alt="Green Loops: Instantly Sell Your Used Books" className="navbar-logo" />
        </Link>
        <SearchBox />
        <div className="navbar-links">
          <Link to="/about" className="navbar-link about-us-button">About Us</Link>          
          <Link to="/cart" className="navbar-cart">
            <img src={recyclingLogo} alt="Recycling Logo" className="navbar-cart-logo" />
            <div className="navbar-cart-count-container">
              <span className="navbar-cart-count">{getTotalQuantity()}</span>
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default DesktopNavbar;
