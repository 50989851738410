// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/navbar';
import SearchResult from './components/SearchResult/searchResult';
import HomeScreen from './components/HomeScreen/homeScreen';
import CartPage from './components/CartPage/cartPage'; // Import CartPage
import { CartProvider } from './contexts/cartContext'; // Import CartProvider
import Checkout from './components/Checkout/checkout'; // Import CheckoutPage
import ThankYouPage from './components/ThankYouPage/thankYouPage';
import Footer from './components/Footer/footer'; 
import AboutUs from './components/AboutUs/aboutUs';
import ReactGA from 'react-ga4';
import './App.css';

function App() {
  ReactGA.initialize('G-ZERBWR3203');

  return (
    <CartProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<HomeScreen />} />
          <Route path="/search/:query" element={<SearchResult />} />
          <Route path="/search/:query/:asin" element={<SearchResult />} />
          <Route path="/cart" element={<CartPage />} /> {/* Add CartPage route */}
          <Route path="/checkout" element={<Checkout/>} /> {/* CheckoutPage Route */}
          <Route path="/thank-you" element={<ThankYouPage/>} /> 
          <Route path="/about" element={<AboutUs/>} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
