import React from 'react';
import './aboutUs.css';
import { Book, Leaf, Heart, ArrowRight } from 'lucide-react';  // Import the Lucide icons
import amazonLogo from '../../media/images/amazon-logo.png';  // Add partner logos
import abebooksLogo from '../../media/images/abe-books-logo.png';
import ebayLogo from '../../media/images/ebay-logo.png';
import facebookLogo from '../../media/images/FB-marketplace-logo.png';
import gtImage from '../../media/images/tech-tower.jpg'; // Image representing the journey
import topLeft from '../../media/images/top-left-about-us.jpg'; 
import topRight from '../../media/images/top-right-about-us.jpg';
import bottomLeft from '../../media/images/bottom-left-about-us.jpg';
import bottomRight from '../../media/images/bottom-right-about-us.jpeg';
import step1 from '../../media/images/step1.jpeg'; 
import step2 from '../../media/images/step2.jpg';
import step3 from '../../media/images/step3.jpg';
import step4 from '../../media/images/step4.jpg';

const AboutUs = () => {
  
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="plant-shop">
      <main>
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-content">
            <h1>Give your books a <strong>new life</strong> with Green Loops</h1>
            <p>Simple, sustainable solutions for recycling and reselling your books while making a positive impact.</p>
            <button className="cta-button" onClick={() => scrollToSection('how-we-work')}>Learn More</button>
          </div>
          <div className="hero-images">
            <div className="col1">
              <img src={topLeft} alt="Book Stack" className="top-left-image" />
              <img src={bottomLeft} alt="Book on Table" className="bottom-left-image" />
            </div>

            <div className="col2">
              <img src={topRight} alt="Flower Jar" className="top-right-image" />
              <img src={bottomRight} alt="Book with Flower" className="bottom-right-image" />
            </div>
          </div>
        </section>

        {/* Divider Line */}
        <span className="divider"></span>

        {/* How We Work Section */}
        <section id="how-we-work" className="how-we-work">
          <h2>How We Work</h2>
          <p>Empowering sustainability by simplifying the process of selling and recycling used books.</p>
          <div className="steps-gallery-scrollable">
            <div className="steps-gallery">
              <div className="step">
                <img src={step1} alt="Step 1" />
                <p className="step-title">1. Select Your Books</p>
                <p className="step-description">Browse through your collection and choose the books you no longer need.</p>
              </div>
              <div className="step">
                <img src={step2} alt="Step 2" />
                <p className="step-title"> 2. Ship Your Books or Schedule a Pickup</p>
                <p className="step-description">Get free shipping, or <span className="bold-text">if you're near Georgia Tech</span>, schedule a free pickup!</p>
                </div>
              <div className="step">
                <img src={step3} alt="Step 3" />
                <p className="step-title">3. Get Paid</p>
                <p className="step-description">Receive instant payment for the books we've received.</p>
              </div>
              <div className="step">
                <img src={step4} alt="Step 4" />
                <p className="step-title">4. Books Find a New Home</p>
                <p className="step-description">Your books are given a second life with someone else who needs them.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Divider Line */}
          <span className="divider"></span>

        {/* Values Section */}
        <section className="values">
        <h2 style={{ fontSize: '4ch' }}>Our Values</h2>
          <div className="values-grid">
            <div className="value-item">
              <Leaf size={48} color="#27ae60" className="value-icon" />
              <h3>Sustainability</h3>
              <p>
                Sustainability is at the core of everything we do. We are committed to minimizing waste by giving used books a second life. By facilitating the reuse of books, we aim to reduce the environmental impact of new production and promote responsible consumption. From our eco-friendly shipping practices to the partnerships we foster, every step we take is guided by our goal to create a more sustainable world.
              </p>
            </div>
            <div className="value-item">
              <Book size={48} color="#27ae60" className="value-icon" />
              <h3>Quality</h3>
              <p>
                We take pride in offering only high-quality books that are carefully selected and inspected. Whether you're buying or selling, we ensure that every transaction meets our stringent standards. Books in good condition find new homes where they can continue to inspire and educate, ensuring that each item on Green Loops serves its purpose for many more years to come.
              </p>
            </div>
            <div className="value-item">
              <Heart size={48} color="#27ae60" className="value-icon" />
              <h3>Customer Satisfaction</h3>
              <p>
                At Green Loops, customer happiness is our top priority. We believe that great service is about more than just transactions; it's about building relationships. Our team is dedicated to making the buying and selling experience smooth, trustworthy, and enjoyable. From the moment you join our community, we are here to support you in every step of your sustainability journey.
              </p>
            </div>
          </div>
        </section>

        {/* Divider Line */}
        <span className="divider"></span>

        {/* Our Journey Section */}
        <section className="journey">
          <div className="journey-container">
            <h2 className="journey-title">Our Sustainable Journey</h2>
            <div className="journey-content">
              <div className="journey-text">
              <p>
              Green Loops took root at Georgia Tech, born from a shared passion for sustainability and a love of books. Our mission was clear: increase the lifecycle of used books by getting them off dusty shelves and into eager hands, promoting both sustainability and knowledge sharing on campus.
              </p>
              <p>
                Today, we've expanded our reach far beyond the Georgia Tech campus, serving book lovers worldwide. While we still offer convenient pickups for our Tech community, we now welcome books from anywhere through our efficient shipping service. Our goal is simple: provide an easy way for you to give your used books a new purpose, handling all the logistics of finding them a new home.
              </p>
              <p>
                At Green Loops, we believe in making sustainability rewarding. Not only do we offer a hassle-free solution for decluttering your bookshelves, but we also provide monetary benefits for your contributions. Join us in our mission to keep books circulating, minds growing, and our planet thriving.
              </p>
              </div>
              <div className="journey-image-container">
                <img src={gtImage} alt="Green Loops Journey" className="journey-image" />
              </div>
            </div>
          </div>
        </section>

        {/* Divider Line */}
        <span className="divider"></span>

        {/* Partners Section */}
        <section className="partners">
        <h2 style={{ fontSize: '2.5em' }}>Our Trusted Partners</h2>
          <p style={{ fontSize: '1.3em', marginBottom: '40px'}}>To find your books a new home we partner with some of the largest platforms, including:</p>
          <div className="partners-logos">
            <img src={abebooksLogo} alt="AbeBooks Logo" className="partner-logo" />
            <img src={amazonLogo} alt="Amazon Logo" className="partner-logo" />
            <img src={ebayLogo} alt="eBay Logo" className="partner-logo" />
            <img src={facebookLogo} alt="Facebook Marketplace Logo" className="partner-logo" />
          </div>
        </section>
      </main>
    </div>
  );
};

export default AboutUs;
